<template>
  <input
    v-model.trim="localValue"
    :type="type"
    :autocomplete="autocomplete"
    :readonly="readOnly"
    :aria-readonly="readOnly"
    :aria-placeholder="placeholder"
    :placeholder="placeholder"
    :aria-describedby="placeholder"
    :disabled="disabled"
    :class="[$style.input, { [$style.disabled]: disabled }]"
    :maxlength="maxLength"
  />
</template>

<script lang="ts" setup>
import { computed } from "vue"

const props = withDefaults(
  defineProps<{
    /** input type (numberはInputNumberを使用してください) */
    type?: "text" | "password" | "email" | "tel" | "url" | "search" | "date"
    modelValue?: string
    /** プレースホルダー */
    placeholder?: string
    /** 非活性 */
    disabled?: boolean
    /** 読み取り専用 */
    readOnly?: boolean
    /** オートコンプリート */
    autocomplete?: string
    maxLength?: number
  }>(),
  {
    modelValue: "",
    type: "text",
    placeholder: "",
    disabled: false,
    readOnly: false,
    autocomplete: undefined,
    maxLength: undefined,
  },
)

const emit = defineEmits<{
  (e: "update:modelValue", modelValue: string): void
}>()

const localValue = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
})
</script>

<style scoped module lang="scss">
@import "./AtomsConsumer";
.input {
  @include text-style;
}
</style>
